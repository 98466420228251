<template lang="html">
  <div>
    <Header :animated="false"/>
    <section id="err404" class="animated jello">
        <div class="container">
            <div class="section-header">
                <h1>{{ $trs('404.title') }}</h1>
                <div class="separator"></div>
            </div>
            <div>
               <p>{{ $trs(sentence, sentenceOpts) }}</p>
                <p>
                  {{ $trs('404.you-can-go') }}
                  <button @click="back()" class="btn btn-outline-primary">{{ $trs('404.back') }}</button>
                  {{ $trs('404.or') }}
                  <button @click="home()" class="btn btn-outline-primary">{{ $trs('404.home') }}</button>
                  {{ $trs('404.instead') }}
                </p>
            </div>
        </div>
    </section>
    <Footer />
  </div>
</template>

<script>
import Header from '@/components/Header.vue';
import Footer from '@/components/Footer.vue';

export default {
  name: 'Err404',
  components: {
    Header,
    Footer
  },
  data() {
    return {
      sentence: "404.path-unknown",
      sentenceOpts: {
        path: this.$route.params.pathMatch
      }
    }
  },
  methods: {
    back() {
      this.$router.go(-1);
    },
    home() {
      this.$router.push({ path: '/' });
    },
  }
}
</script>

<style lang="css" scoped>
#err404 {
    padding: 60px 0;
    overflow: hidden;
    background: #fff;
}

/* footer revealed */
#err404 {
    margin-bottom: 150px;
}

.btn-outline-primary {
    color: #09667f; /* primary theme dark */             
    border-color: #09667f; /* primary theme dark */             
}

.btn-outline-primary:hover {
    border-color: #09667f; /* primary theme dark */             
    background: #75b4d3; /* secondary theme light */   
}
</style>
